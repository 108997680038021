import type { BuiltinColorPaletteKey } from '../types/color_palette';

export const BUILTIN_COLOR_PALETTE_KEYS = {
  DEFAULT: 'DEFAULT',
  MONOCHROME: 'MONOCHROME',
  PASTEL: 'PASTEL',
} as const;

export const BUILTIN_COLOR_PALETTE_OPTIONS: { key: BuiltinColorPaletteKey; label: string }[] = [
  { key: BUILTIN_COLOR_PALETTE_KEYS.DEFAULT, label: 'Default' },
  // MEMO: Default 以外は別途色を調整してから出す
  // https://github.com/codatum/codatum/issues/5397
  // { key: BUILTIN_COLOR_PALETTE_KEYS.MONOCHROME, label: 'Monochrome' },
  // { key: BUILTIN_COLOR_PALETTE_KEYS.PASTEL, label: 'Pastel' },
];

export const PALETTE_COLOR_NAMES = {
  SUCCESS: 'SUCCESS',
  WARN: 'WARN',
  DANGER: 'DANGER',

  SERIES1: 'SERIES1',
  SERIES2: 'SERIES2',
  SERIES3: 'SERIES3',
  SERIES4: 'SERIES4',
  SERIES5: 'SERIES5',
  SERIES6: 'SERIES6',
  SERIES7: 'SERIES7',
  SERIES8: 'SERIES8',
  SERIES9: 'SERIES9',
  SERIES10: 'SERIES10',
  SERIES11: 'SERIES11',
  SERIES12: 'SERIES12',

  GRADIENT_HIGH: 'GRADIENT_HIGH',
  GRADIENT_MID: 'GRADIENT_MID',
  GRADIENT_LOW: 'GRADIENT_LOW',
} as const;

export const PALETTE_THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
} as const;
