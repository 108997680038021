import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faToggleOn,
  faToggleOff,
  faChevronUp,
  faChevronDown,
  faChevronsDown,
  faChevronRight,
  faChevronLeft,
  faChevronsLeft,
  faPlayCircle,
  faTimes,
  faSpinner,
  faCheck,
  faSortUp,
  faEllipsisH,
  faPaperPlane,
  faExclamation,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faExclamationCircle,
  faCheckCircle,
  faCircle,
  faCalendarAlt,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faGripVertical,
  faInfoCircle,
  faAnalytics,
  faAngleRight,
  faAngleLeft,
  faSquare,
  faCheckSquare,
  faImage,
  faMouse,
  faRedoAlt,
  faCaretUp,
  faCaretDown,
  faBold,
  faHighlighter,
  faUnderline,
  faItalic,
  faStrikethrough,
  faSquareRootAlt,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faUsers,
  faExclamationTriangle,
  faEdit,
  faCaretRight,
  faUndo,
  faIndent,
  faFunction,
  faFileExcel,
  faSync,
  faBarsFilter,
  faClipboard,
  faVideo,
  faQuoteLeft,
  faPlus,
  faRandom,
  faHeading,
  faObjectGroup,
  faText,
  faAngleDown,
  faArrowUpRightFromSquare,
  faPlay,
  faEye,
  faEyeSlash,
  faGripDotsVertical,
  faBars,
  faPencil,
  faStar,
  faArrowsLeftRight,
  faGripLines,
  faLink,
  faLinkSlash,
  faCircleCaretDown,
  faHashtag,
  faFont,
  faCalendar,
  faQuestion,
  faQuestionCircle as faQuestionCircleSolid,
  faSparkle,
  faCameraViewfinder,
  faChartPieSimple,
  faCode as faCodeSolid,
  faChartScatter,
  faRotateLeft,
  faClock as faClockSolid,
  faList as faListSolid,
  faListOl as faListOlSolid,
  faUser as faUserSolid,
  faUserGroup as faUserGroupSolid,
  faDownFromLine,
  faArrowUpToLine,
  faMemo,
  faPaintBrushFine,
  faAt as faAtSolid,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faTrash,
  faBell,
  faQuestionCircle,
  faSunBright,
  faMoon,
  faGear,
  faFilter,
  faFilterSlash,
  faHouse,
  faUserGroup,
  faUserGroupSimple,
  faUserShield,
  faCreditCard,
  faPlug,
  faLock,
  faLockAlt,
  faLockOpen,
  faBuildings,
  faUserSlash,
  faFolder,
  faFolderOpen,
  faCommentSmile,
  faBookBlank,
  faKeyboard,
  faComment,
  faDatabase,
  faClockRotateLeft,
  faPrint,
  faFileChartLine,
  faTablePivot,
  faFaceMeh,
  faBookOpenReader,
  faFileCode,
  faComments,
  faDiagramProject,
  faScrewdriverWrench,
  faSmile,
  faArrowLeftToLine,
  faArrowRightToLine,
  faFile,
  faPersonDigging,
  faRobot,
  faEnvelopeOpen,
  faEnvelope,
  faBarsSort,
  faThumbtack,
  faGridHorizontal,
  faSquareDashed,
  faNoteSticky,
  faNote,
  faSliders,
  faListTree,
  faBookOpen,
  faGrid,
  faGrid2,
  faArrowRightFromBracket,
  faListUl,
  faListCheck,
  faUserGear,
  faSparkles,
  faPenNib,
  faVideo as faVideoRegular,
  faScreenshot as faScreenshotRegular,
  faLightbulb,
  faMessages,
  faPen,
  faBug,
  faTable,
  faChartSimple,
  faInputNumeric,
  faCode,
  faCodeSimple,
  faBracketsCurly,
  faH1,
  faH2,
  faH3,
  faList,
  faListOl,
  faHorizontalRule,
  faInfo,
  faBlockQuote,
  faBorderAll,
  faImageLandscape,
  faClapperboardPlay,
  faChevronDown as faChevronDownRegular,
  faChevronRight as faChevronRightRegular,
  faDiagramSubtask,
  faFunction as faFunctionRegular,
  faSquareDashedCirclePlus,
  faHeart,
  faArrowDownLeftAndArrowUpRightToCenter,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faClock,
  faSearch,
  faInfoCircle as faInfoCircleRegular,
  faCircleDashed,
  faShieldKeyhole,
  faUser,
  faShareNodes,
  faHourglass,
  faSquarePlus,
  faBuilding,
  faEye as faEyeRegular,
  faEyeSlash as faEyeSlashRegular,
  faKiwiBird,
  faFilePen,
  faStar as faStarRegular,
  faListTimeline,
  faChartPyramid,
  faSquare8,
  faChartPieSimple as faChartPieSimpleRegular,
  faChartScatter as faChartScatterRegular,
  faSitemap,
  faDiagramSankey,
  faUserChart,
  faFileCheck,
  faArrowDownShortWide,
  faChartLine,
  faTableList,
  faFileChartColumn,
  faTag,
  faUserPlus,
  faExpand,
  faArrowUpRight,
  faCircleXmark,
  faKey,
  faNetworkWired,
  faSquareXmark,
  faFaceDizzy,
  faMagnifyingGlassChart,
  faSquareCode,
  faArrowsFromLine,
  faH,
  faCopy,
  faBook,
  faDiagramNext,
  faDiagramSuccessor,
  faCircleArrowUp,
  faGem,
  faDownload,
  faLineColumns,
  faColumns3,
  faArrowsToDottedLine,
  faFrame,
  faImage as faImageRegular,
  faAsterisk,
  faFontCase,
  faRepeat,
  faRepeat1,
  faFire,
  faFileMagnifyingGlass,
  faGlobe,
  faHourglassClock,
  faBrowsers,
  faThumbsUp,
  faThumbsDown,
  faMegaphone,
  faAt,
  faPenToSquare,
  faFlag,
  faMessagePen,
  faArrowUpRightFromSquare as faArrowUpRightFromSquareRegular,
  faTriangleExclamation,
  faSlidersUp,
  faArrowTurnDownLeft,
  faPalette,
  faShuffle,
  faCircleExclamation,
  faBarsProgress,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faColumns2,
  faColumns4,
  faColumns5,
  faColumnMinus,
  faColumnPlus,
  faRowMinus,
  faRowPlus,
  faExternalTable,
  faMaterializedView,
} from './customIcons';

let isAdded = false;
export const addIcons = () => {
  if (isAdded) {
    return;
  }
  isAdded = true;
  library.add(
    faPlug,
    faToggleOn,
    faToggleOff,
    faChevronUp,
    faChevronDown,
    faChevronsDown,
    faChevronRight,
    faChevronLeft,
    faChevronsLeft,
    faCode,
    faCodeSimple,
    faPlayCircle,
    faTimes,
    faLink,
    faLinkSlash,
    faSpinner,
    faCheck,
    faSortUp,
    faEllipsisH,
    faPen,
    faTrash,
    faPaperPlane,
    faExclamation,
    faArrowUp,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faExclamationCircle,
    faCheckCircle,
    faCircle,
    faCalendarAlt,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faBorderAll,
    faGripVertical,
    faInfoCircle,
    faAnalytics,
    faAngleRight,
    faAngleLeft,
    faSquare,
    faCheckSquare,
    faImage,
    faImageRegular,
    faMouse,
    faRedoAlt,
    faQuestionCircle,
    faComment,
    faSearch,
    faAsterisk,
    faFontCase,
    faCaretUp,
    faCaretDown,
    faRepeat,
    faRepeat1,
    faBold,
    faHighlighter,
    faUnderline,
    faItalic,
    faStrikethrough,
    faSquareRootAlt,
    faAlignLeft,
    faAlignCenter,
    faAlignRight,
    faComments,
    faLock,
    faUserSolid,
    faUsers,
    faExclamationTriangle,
    faDatabase,
    faEdit,
    faCaretRight,
    faUndo,
    faIndent,
    faBracketsCurly,
    faFunction,
    faFileCode,
    faGear,
    faFileChartLine,
    faFileExcel,
    faTable,
    faSync,
    faBarsFilter,
    faExpand,
    faClipboard,
    faVideo,
    faAt,
    faAtSolid,
    faQuoteLeft,
    faSmile,
    faClock,
    faPlus,
    faRandom,
    faHeading,
    faObjectGroup,
    faText,
    faList,
    faListOl,
    faListCheck,
    faCopy,
    faLockAlt,
    faLockOpen,
    faPalette,
    faTablePivot,
    faSunBright,
    faMoon,
    faBell,
    faAngleDown,
    faArrowUpRightFromSquare,
    faFolder,
    faFolderOpen,
    faMessages,
    faPen,
    faPlay,
    faClockRotateLeft,
    faUserGroup,
    faUserGroupSimple,
    faUserShield,
    faCreditCard,
    faUserSlash,
    faEye,
    faEyeSlash,
    faDiagramProject,
    faGripDotsVertical,
    faBars,
    faHouse,
    faBookOpenReader,
    faPencil,
    faStar,
    faFaceMeh,
    faCommentSmile,
    faBookBlank,
    faKeyboard,
    faArrowsLeftRight,
    faScrewdriverWrench,
    faPrint,
    faFilter,
    faFilterSlash,
    faFrame,
    faGripLines,
    faBuildings,
    faArrowLeftToLine,
    faArrowRightToLine,
    faFile,
    faPersonDigging,
    faSparkles,
    faRobot,
    faEnvelopeOpen,
    faEnvelope,
    faBarsSort,
    faThumbtack,
    faGridHorizontal,
    faSquareDashed,
    faNoteSticky,
    faNote,
    faSliders,
    faListTree,
    faBookOpen,
    faGrid,
    faGrid2,
    faCircleCaretDown,
    faArrowRightFromBracket,
    faListUl,
    faUserGear,
    faHashtag,
    faFont,
    faCalendar,
    faQuestion,
    faQuestionCircleSolid,
    faSparkle,
    faPenNib,
    faCameraViewfinder,
    faVideoRegular,
    faScreenshotRegular,
    faLightbulb,
    faBug,
    faTable,
    faChartSimple,
    faInputNumeric,
    faH1,
    faH2,
    faH3,
    faHorizontalRule,
    faInfo,
    faBlockQuote,
    faImageLandscape,
    faClapperboardPlay,
    faChevronDownRegular,
    faChevronRightRegular,
    faDiagramSubtask,
    faFunctionRegular,
    faSquareDashedCirclePlus,
    faChartPieSimple,
    faHeart,
    faCodeSolid,
    faArrowDownLeftAndArrowUpRightToCenter,
    faArrowUpRightAndArrowDownLeftFromCenter,
    faChartScatter,
    faRotateLeft,
    faMagnifyingGlassChart,
    faClockSolid,
    faInfoCircleRegular,
    faCircleDashed,
    faShieldKeyhole,
    faUser,
    faShareNodes,
    faListSolid,
    faListOlSolid,
    faUserGroupSolid,
    faHourglass,
    faDownFromLine,
    faArrowUpToLine,
    faSquarePlus,
    faMemo,
    faBuilding,
    faPaintBrushFine,
    faEyeRegular,
    faEyeSlashRegular,
    faKiwiBird,
    faFilePen,
    faStarRegular,
    faListTimeline,
    faChartPyramid,
    faSquare8,
    faChartPieSimpleRegular,
    faChartScatterRegular,
    faSitemap,
    faDiagramSankey,
    faUserChart,
    faFileCheck,
    faArrowDownShortWide,
    faChartLine,
    faTableList,
    faFileChartColumn,
    faTag,
    faUserPlus,
    faArrowUpRight,
    faCircleXmark,
    faKey,
    faNetworkWired,
    faSquareXmark,
    faFaceDizzy,
    faSquareCode,
    faArrowsFromLine,
    faH,
    faBook,
    faDiagramNext,
    faDiagramSuccessor,
    faCircleArrowUp,
    faGem,
    faDownload,
    faLineColumns,
    faColumns2,
    faColumns3,
    faColumns4,
    faColumns5,
    faColumnMinus,
    faColumnPlus,
    faRowMinus,
    faRowPlus,
    faArrowsToDottedLine,
    faFire,
    faFileMagnifyingGlass,
    faGlobe,
    faHourglassClock,
    faExternalTable,
    faMaterializedView,
    faBrowsers,
    faThumbsUp,
    faThumbsDown,
    faMegaphone,
    faPenToSquare,
    faFlag,
    faMessagePen,
    faArrowUpRightFromSquareRegular,
    faTriangleExclamation,
    faSlidersUp,
    faArrowTurnDownLeft,
    faShuffle,
    faCircleExclamation,
    faBarsProgress,
  );
};
